<template>
  <div>
    <div class="home">
      <img class="home_background" src="@/assets/banner-home.jpg">
    </div>

    <div class="section_container my-5">
      <div class="container">
        <h1>Search: {{ query }}</h1>
        <input
          type="text"
          class="form-control"
          :value="query"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search',

  data: () => ({
    query: '',
  }),

  created() {
    this.query = this.$route.query.q;
  },
};
</script>
